import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UnitDetail from "./UnitDetail";
import Heading from "./Heading";
import { useBuildingsById } from "../../../../../reactQuery/building";
import GlobalSearch from "../../../../../components/Search";
import Table from "./Table";
import { useErrorBoundary } from "react-error-boundary";
import BackButton from "../../../../../components/BackButton";

import Mailer from "./Mailer";
import LeadComplianceHeading from "./LeadComplianceHeading";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";

function BuildingDetail() {
  const param = useParams();
    const [year, setYear] = useCustomSearchParams("year");
  const { showBoundary } = useErrorBoundary();
  const {
    detail,
    isLoading: detailLoading,
    responseData,
    totalRecords,
    isError,
    error,
    refetch,
  } = useBuildingsById({ id: param.buildingId ,year:year || new Date().getFullYear()});

  useEffect(() => {
    refetch();
  }, [year]);

  useEffect(() => {
    if (error) {
      showBoundary(error);
    }
  }, [error]);

  const refetchUnits = ()=>{
    refetch();
  }

  return (
    <div>
      <div className="d-flex justify-content-space-between mb-4">
        <div>
          {/* {detail && (
            <Mailer
              data={{
                building_id: param.buildingId,
                mailer_program: detail?.mailer_program || false,
              }}
            />
          )} */}
        </div>
        <BackButton />
      </div>

      {param?.clientId && (
        <UnitDetail detail={detail} detailLoading={detailLoading} />
      )}
      <Heading detail={detail} />
      <div className="tickets-filter d-flex justify-content-space-between mt-4 p-0-important height-62">
        <div className="table-Filters-padding">
          <GlobalSearch />
        </div>
        <LeadComplianceHeading
          building={param.buildingId}
          client={param.clientId}
          isGrey={!detail?.mailer_program}
        />
      </div>
      <Table
        responseData={responseData}
        totalRecords={totalRecords}
        isLoading={detailLoading}
        isError={isError}
        error={error}
        detail={detail}
        refetchUnits={refetchUnits}
      />
    </div>
  );
}

export default BuildingDetail;
