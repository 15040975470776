import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import RedFlag from "../../../../../styles/svg/red-unit.svg";
import GreenFlag from "../../../../../styles/svg/green-unit.svg";
import frictionRed from "../../../../../styles/svg/red-friction.svg";
import frictionGreen from "../../../../../styles/svg/green-friction.svg";
import DefaultServiceIcon from "../../../../../styles/svg/default-service-icon.svg";
import CustomTable from "../../../../../components/Table/CustomTable";
import Loader from "../../../../../components/Loader";
import { useCustomParams } from "../../../../../hooks/useCustomParams";
import { servicesVariables } from "../../../../../utils/constants";
import useDownloader from "react-use-downloader";
import { downloadReport } from "../../../../../sevices/jobApi";
import ViewDocument from "../RecentUploads/ViewDocument";
import Schedule from "./Schedule";
import moment from "moment";
import ViewSurvey from "./ViewSurvey";
import InspectionScheduled from "../../../../LeadCompliance/components/Table/InspectionScheduled";
import Green from "../../../../../styles/svg/compliance-green.svg";
import Red from "../../../../../styles/svg/red-cross-icon.svg";
import KnobCovers from "../../../../LeadCompliance/components/Table/KnobCovers";
import WindowGuards from "../../../../LeadCompliance/components/Table/WindowGuards";


function Table({
  responseData,
  totalRecords,
  isLoading,
  isError,
  error,
  detail,
  refetchUnits,
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [search] = useCustomParams("search");
  const redirect = useNavigate();

  const { download } = useDownloader();

  const downloadFile = (id) => {
    downloadReport(id)
      .then((res) => {
        download(
          `${process.env.REACT_APP_BASE_URL}${res.data.data.file_url}`,
          res.data.data.file_name
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "unit_number",
      headerName: "#Unit",
      renderCell: (params) => {
        return (
          <p
            className="unit-ticket cursor-pointer"
            onClick={() => redirect(`unit/${params.row.unit_id}`)}
          >
            {params.value}
          </p>
        );
      },
      minWidth: 100,
      flex: 1,
      // editable: true,
      // sortable: false,
      cellClassName: (value) => {
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "building_address",
      headerName: "Building",
      renderCell: (params) => {
        return <p className="">{detail?.address}</p>;
      },
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
      cellClassName: (value) => {
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "job_data",
      headerName: "Report On File",
      renderCell: (params) => {
        const completedJobs = params?.value.filter(
          (el) =>
            el.report_status != "Pending" && el.report_status != "No Access"
        );
        let obj = {};
        for (let i = 0; i < completedJobs.length; i++) {
          let job = completedJobs[i];
          if (job.service_id && job?.attachment) {
            if (
              job?.report_status == "Negative" ||
              job?.report_status == "Positive"
            ) {
              if (servicesVariables.xrfEnitrUnitNegative == job.service_id) {
                if (
                  !Object.hasOwn(obj, "xrfEntireUnitNegative") &&
                  job?.report_status == "Negative"
                ) {
                  obj.xrfEntireUnitNegative = job;
                } else if (
                  !Object.hasOwn(obj, "xrfEntireUnitPositive") &&
                  job?.report_status == "Positive"
                ) {
                  obj.xrfEntireUnitPositive = job;
                }
              } else if (
                servicesVariables.xrfFrictionPositive == job.service_id
              ) {
                if (
                  !Object.hasOwn(obj, "xrfFrinctionPositive") &&
                  job?.report_status == "Positive"
                ) {
                  obj.xrfFrinctionPositive = job;
                } else if (
                  !Object.hasOwn(obj, "xrfFrinctionNegative") &&
                  job?.report_status == "Negative"
                ) {
                  obj.xrfFrinctionNegative = job;
                }
              }
            }
          }
        }
        return (
          <div className="w-100 d-flex justify-content-center">
            {obj?.xrfEntireUnitNegative ? (
              <ViewDocument
                jobId={obj?.xrfEntireUnitNegative.job_id}
                icon={GreenFlag}
              />
            ) : (
              ""
            )}
            {obj?.xrfEntireUnitPositive ? (
              <ViewDocument
                jobId={obj?.xrfEntireUnitPositive.job_id}
                icon={RedFlag}
              />
            ) : (
              ""
            )}
            {/* {obj?.xrfEntireUnit ? (
              obj?.xrfEntireUnit.report_status == "Negative" ? (
                <ViewDocument
                  jobId={obj?.xrfEntireUnit.job_id}
                  icon={GreenFlag}
                />
              ) : obj?.xrfEntireUnit.report_status == "Positive" ? (
                <ViewDocument
                  jobId={obj?.xrfEntireUnit.job_id}
                  icon={RedFlag}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )} */}

            {obj?.xrfFrinctionNegative ? (
              <ViewDocument
                jobId={obj?.xrfFrinctionNegative.job_id}
                icon={frictionGreen}
              />
            ) : (
              ""
            )}
            {obj?.xrfFrinctionPositive ? (
              <ViewDocument
                jobId={obj?.xrfFrinctionPositive.job_id}
                icon={frictionRed}
              />
            ) : (
              ""
            )}
            {/* {obj?.xrfFrinction ? (
              obj?.xrfFrinction.report_status == "Negative" ? (
                <ViewDocument
                  jobId={obj?.xrfFrinction.job_id}
                  icon={frictionGreen}
                />
              ) : obj?.xrfFrinction.report_status == "Positive" ? (
                <ViewDocument
                  jobId={obj?.xrfFrinction.job_id}
                  icon={frictionRed}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )} */}
          </div>
        );
      },
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Report On File</p>
        </div>
      ),
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
      headerClassName: "center-header",
      cellClassName: (value) => {
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "schedule",
      headerName: "Actions",
      renderCell: (params) => {
        return <Schedule params={params} />;
      },
      minWidth: 110,
      flex: 0.8,
      // editable: true,
      sortable: false,
      cellClassName: (value) => {
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "pending-job",
      headerName: "Pending Ticket",
      renderCell: (params) => {
        const pendingJobs = params?.row?.job_data
          .filter((el) => el.report_status == "Pending")
          .filter((el) => moment(el.scheduled_date).isAfter(moment(), "day"));
        return (
          <p className="d-flex">
            {pendingJobs?.length > 0 ? (
              <Tooltip
                title={pendingJobs.map((el, idx) => (
                  <p>
                    {/* {`${idx > 0 ? ", " : ""}`} */}
                    {moment(el?.scheduled_date).format("MM/DD/YYYY")}
                  </p>
                ))}
              >
                <p>
                  {moment(pendingJobs[0]?.scheduled_date).format("MM/DD/YYYY")}
                </p>
              </Tooltip>
            ) : (
              ""
            )}
          </p>
        );
      },
      minWidth: 110,
      flex: 0.8,
      // editable: true,
      sortable: false,
      cellClassName: (value) => {
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },

    {
      field: "mailer_count",
      headerName: "Mailers Send",
      renderCell: (params) => {
        return <p className="">{params.row?.compliance?.mailer_count}</p>;
      },
      minWidth: 100,
      flex: 0.7,
      // editable: true,
      sortable: false,
      headerClassName: !detail?.mailer_program
        ? "gred-out"
        : "highlighted_row_for_compliance",

      cellClassName: (value) => {
        return !detail?.mailer_program
          ? "gred-out"
          : value.row.is_exempt
          ? "highlighted_row"
          : "";
      },
    },
    {
      field: "window_guards",
      headerName: "Window Guards",
      renderCell: (params) => {
        return params.row.compliance &&
          Object.keys(params.row.compliance).length === 0 ? (
          ""
        ) : (
          <WindowGuards params={params} refetchUnits={refetchUnits} />
        );
      },
      minWidth: 130,
      flex: 1,
      // editable: true,
      sortable: false,
      headerClassName: !detail?.mailer_program
        ? "gred-out"
        : "highlighted_row_for_compliance",
      cellClassName: (value) => {
        return !detail?.mailer_program
          ? "gred-out"
          : value.row.is_exempt
          ? "highlighted_row"
          : "";
      },
    },
    {
      field: "knob_covers",
      headerName: "Knob Covers",
      renderCell: (params) => {
        return params.row.compliance &&
          Object.keys(params.row.compliance).length === 0 ? (
          ""
        ) : (
          <KnobCovers params={params} refetchUnits ={refetchUnits}/>
        );
      },
      minWidth: 100,
      flex: 1,
      // editable: true,
      sortable: false,
      headerClassName: !detail?.mailer_program
        ? "gred-out"
        : "highlighted_row_for_compliance",
      cellClassName: (value) => {
        return !detail?.mailer_program
          ? "gred-out"
          : value.row.is_exempt
          ? "highlighted_row"
          : "";
      },
    },
    {
      field: "inspection",
      headerName: "Visual Inspectionn",
      renderCell: (params) => {
        return (
          <InspectionScheduled
            params={params}
            isFromUnit={true}
            refetchUnits={refetchUnits}
          />
        );
        // return <p></p>;
      },
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Visual Inspection</p>
        </div>
      ),
      minWidth: 100,
      flex: 1,
      // editable: true,
      sortable: false,
      headerClassName: !detail?.mailer_program
        ? "gred-out center-header"
        : "highlighted_row_for_compliance center-header",
      cellClassName: (value) => {
        return !detail?.mailer_program
          ? "gred-out"
          : value.row.is_exempt
          ? "highlighted_row"
          : "";
      },
    },
    {
      field: "survey",
      headerName: "Survey",
      renderCell: (params) => {
        return <ViewSurvey params={params} />;
      },
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Survey</p>
        </div>
      ),
      minWidth: 110,
      flex: 0.8,
      // editable: true,
      sortable: false,
      headerClassName: !detail?.mailer_program
        ? "gred-out center-header"
        : "highlighted_row_for_compliance center-header",
      cellClassName: (value) => {
        return !detail?.mailer_program
          ? "gred-out"
          : value.row.is_exempt
          ? "highlighted_row"
          : "";
      },
    },
  ];

  const filteredUnits = search
    ? responseData?.filter((item) =>
        item.unit_number.toLowerCase().includes(search.toLowerCase())
      )
    : responseData;

  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <CustomTable
            initialRows={filteredUnits}
            columns={columns}
            defaultSort={"unit_number"}
            headerBgColor={"#F5F5F9"}
            rowCount={totalRecords}
            setPaginationModel={(data) => {
              setPage(data.page);
              setPageSize(data.pageSize);
            }}
            page={page}
            pageSize={pageSize}
            subHeight={282}
            hideFooter={true}
            useMaxHeight={true}
          />
        </>
      )}
    </>
  );
}

export default Table;
