import React, { useEffect, useState } from "react";
import { useLeadCompliance } from "../../../../reactQuery/leadCompliance";
import CustomTable from "../../../../components/Table/CustomTable";
import { columns } from "./tableColumns";
import Loader from "../../../../components/Loader";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useCustomParams } from "../../../../hooks/useCustomParams";
import { useDispatch } from "react-redux";
import { setStatusCount } from "../../../../redux/complianceSlice";

function Table({clientId}) {
  const dispatch = useDispatch();
  const [refetc, setRefetch] = useCustomSearchParams("refetch");
  const [year, setYear] = useCustomSearchParams("year");
  const [status, updateStatus] = useCustomParams("status");
  const [building, setBuilding] = useCustomSearchParams("building");
  const [client, setClient] = useCustomSearchParams("client");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
    const [sort, setSort, removeSort] = useCustomParams("sort");

  const prepareQueryFilters = `?page=${page + 1}&page_size=${pageSize}${
    year ? `&year=${year}` : ""
  }${status ? `&status=${status}` : ""}${
    client || clientId ? `&client_id=${client || clientId}` : ""
  }${building ? `&building_id=${building}` : ""}${sort
      ? `&sort_by=${sort.split("-")[1]}&sort=${sort.split("-")[0]}`
      : ""}`;
  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    statusCount,
    isRefetching,
  } = useLeadCompliance(prepareQueryFilters);

  useEffect(() => {
    refetch();
  }, [year, client, building, status, page, pageSize, refetc, clientId, sort]);

  const handleSortModelChange = (newSortModel) => {
    if (newSortModel.length > 0) {
      const { field, sort } = newSortModel[0];
      setSort("sort", `${field}-${sort}`);
    } else {
      removeSort("sort");
    }
  };

  useEffect(() => {
    dispatch(setStatusCount(statusCount));
  }, [statusCount]);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (currentYear < year && typeof totalRecords == "number") {
      setYear("show-icon", totalRecords < 1 ? true : false);
    }
  }, [year, totalRecords]);

  return (
    <div className="client-table-page">
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <CustomTable
          initialRows={responseData}
          columns={columns}
          headerBgColor={"#F5F5F9"}
          rowCount={statusCount ? statusCount[status || "total"] || 0 : 0}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          sort={sort ? sort.split("-")[1] : "asc"}
          defaultSort={sort ? sort.split("-")[0] : "building_address"}
          onSortModelChange={handleSortModelChange}
          page={page}
          pageSize={pageSize}
          subHeight={302}
          isLoading={isRefetching}
        />
      )}
    </div>
  );
}

export default Table;
