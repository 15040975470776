import React, { useState } from 'react'
import { useCustomSearchParams } from '../../../../hooks/useCustomSearchParams';
import LongFormDialog from '../../../../components/Dialog/LongFormDialog';
import EnterSurveyForm from '../Header/EnterSurveyForm';

function ApartmentNumberColumns({ params }) {
     const [anchorEl, setAnchorEl] = useState(false);
     const [refetch, setRefetch] = useCustomSearchParams("refetch");

     const role = JSON.parse(localStorage.getItem("userData")).role;

     const handleClose = () => {
       setAnchorEl(false);
       setRefetch("refetch", refetch ? refetch + 1 : 1);
     };
     const handleClick = () => {
      role === 'admin' && setAnchorEl(true);
     };
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Survey Response"}
          btnTitle={"Confirm"}
        >
          <EnterSurveyForm
            onClose={handleClose}
            initialData={{ dates: new Date(), survey_id: params.row.compliance?.survey_id }}
          />
        </LongFormDialog>
      )}
      <p
        className={`unit-ticket ${
          role === "admin" ? "cursor-pointer" : ""
        } `}
        onClick={handleClick}
      >
        {params.value}
      </p>
    </>
  );
}

export default ApartmentNumberColumns