import React from "react";
import Calendar from "../../Calendar";
import Tickets from "../../Tickets";
import Dashboard from "../../Dashboard/components/Dashboard";
import Service from "../../services";
import Terms from "../../Terms";
import InspectorAssign from "../../InspectorAssign";
import InspectorModule from "../../InspectorModule";
import InspectorSchedule from "../../InspectorModule/InspectorSchedule";
import Client from "../../Client";
import ClientDetail from "../../Client/detail";
import ClientCompliance from "../../Client/detail/components/AnnualCompliance";
import ClientTickets from "../../Client/detail/components/Tickets";
import BuildingDetail from "../../Client/detail/components/Units";
import UnitTickets from "../../Client/detail/components/UnitTickets";
import InspectorRole from "../../InspectorRole";
import LeadCompliance from "../../LeadCompliance";

export default function Routes() {
  const role = JSON.parse(localStorage.getItem("userData"))?.role;
  if (role == "admin")
    return {
      element: <Dashboard />,
      //   errorElement: <RouterErrorPage />,
      children: [
        {
          path: "/calendar",
          element: <Calendar />,
        },
        {
          path: "/ticket",
          element: <Tickets />,
        },
        {
          path: "ticket/inspector-assign",
          element: <InspectorAssign />,
        },
        {
          path: "/client",
          element: <Client />,
        },
        {
          path: "/client/:clientId",
          element: <ClientDetail />,
        },
        {
          path: "/client/:clientId/compliance",
          element: <ClientCompliance />,
        },
        {
          path: "/client/:clientId/building/:buildingId",
          element: <BuildingDetail />,
        },
        {
          path: "/client/:clientId/building/:buildingId/unit/:unitId",
          element: <UnitTickets />,
        },
        {
          path: "/inspector",
          element: <InspectorModule />,
        },
        {
          path: "/inspector/:inspectorId",
          element: <InspectorSchedule />,
        },
        {
          path: "/service",
          element: <Service />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
        {
          path: "/lead-compliance",
          element: <LeadCompliance />,
        },
      ],
    };
  else if (role == "client")
    return {
      element: <Dashboard />,
      //   errorElement: <RouterErrorPage />,
      children: [
        {
          path: "/client/:clientId",
          element: <ClientDetail />,
        },
        {
          path: "/client/:clientId/compliance",
          element: <ClientCompliance />,
        },
        {
          path: "/client/:clientId/building/:buildingId",
          element: <BuildingDetail />,
        },
        {
          path: "/client/:clientId/building/:buildingId/unit/:unitId",
          element: <UnitTickets />,
        },
        {
          path: "/client/:clientId/tickets",
          element: <ClientTickets />,
        },
      ],
    };
  else if (role == "inspector")
    return {
      element: <Dashboard />,
      //   errorElement: <RouterErrorPage />,
      children: [
        {
          path: "/inspector-role",
          element: <InspectorRole />,
        },
      ],
    };
}
