import React from "react";

function ViewFile({ url }) {
  return (
    <div className="mb-4">
      <embed
        src={`${process.env.REACT_APP_BASE_URL}${url}`}
        width="100%"
        style={{ minHeight: "80vh", border: "none" }}
        title="PDF Viewer"
      />
    </div>
  );
}

export default ViewFile;
