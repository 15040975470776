
export const dustWipeTemplate = () => {
  return `<!DOCTYPE html>
<html>
<head>
<title>Dust Wipe</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 18px; line-height: 25px; text-align: justify;">
 
<div style="padding: 40px 60px; position: relative; font-family: arial;">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="font-size: 16px;"><TODAY-DATE></p>
	  
	<p style="display: flex; margin-top: 30px;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Address:</strong> 
		<span style="width: 100%; display: inline-block;"><ADDRESS>, Apt. <APT><br><CITY> , <STATE> <ZIP-CODE><br>Year Built: <YEAR-BUILT></span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Address of Owner:</strong>
		<span style="width: 100%; display: inline-block;"><BUILDING-CORPORATION-NAME> <br>
			<MAILING-ADDRESS><br>
			<MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Testing Contractor:</strong> 
		<span style="width: 100%; display: inline-block;">RAZA Environmental Inc.<br>
			35 East Grassy Sprain Road,<br>
			Suite 302, Yonkers, NY 10710</span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Description of test results:</strong> 
		<span style="width: 100%; display: inline-block;"><em>Attached</em></span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Laboratory used:</strong> 
		<span style="width: 100%; display: inline-block;">Eastern Analytical Services Inc. <br>
4 Westchester Plaza <br>
Elmsford, NY 10523 - 1610</span>
	</p>

	<p style="margin-top: 30px;"><strong style="text-decoration: underline;"><NO-OF-SAMPLES> dust wipe samples</strong> were taken on <strong style="text-decoration: underline;"><SCHEDULE-DATE></strong> by EPA Certified lead inspector <strong style="text-decoration: underline;"><INSPECTOR-NAME></strong> (EPA certificate # <INSPECTOR-CERTIFICATION-ID> attached). They were analyzed by Eastern Analytical Services Inc – NYS Lab #10851, for lead dust in accordance with EPA method 3050B/700B.</p>  

	<p><strong>All sample area results must be below the NYC lead dust acceptable thresholds listed below: Current NYC Lead Dust Acceptable Thresholds:</strong></p>

	<ul style="padding-left: 20px;">
		<li style="margin:2px 0px;"><strong>Floors: 5 µg/ft²</strong></li>
		<li style="margin:2px 0px;"><strong>Windowsills: 40 µg/ft²</strong></li>
		<li style="margin:2px 0px;"><strong>Window Wells: 100 µg/ft²</strong></li>
	</ul>

	<p>If you have any questions or require additional information, please feel free to contact me at the number above.</p>

	<p style="margin:0px;">Sincerely,</p> 
	<p style="height: 30px;"></p>
	<p>Ramo Kucevic <br>Director of Lead Paint Operations<br>Attachment - EAS Report</p> 

	 

</div>

</body>
</html>`;
};

export const xrfEnitrUnitNegativeTemplate = ()=>{
	return `<!DOCTYPE html>
<html>
<head>
<title>XRF</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0;  font-size: 20px; line-height: 26px; text-align: justify;">
 
<div style="padding: 40px 60px; position: relative; font-family: arial;">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="color: #5A99D4"><TODAY-DATE></p>

	<p style="color: #5A99D4"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></p>

	<p style="margin-top: 25px;">Re: Premises:<br> <span style="color: #5A99D4"><ADDRESS><br><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>

	<h1 style="text-align: center; font-size: 20px; margin-bottom: 20px; text-decoration: underline;margin-top: 30px;">Lead - Based Paint WITHIN NYC Acceptable Thresholds</h1>

	<p style="margin-top: 0;">On <span style="color: #5A99D4"><SCHEDULE-DATE></span> lead-based paint testing was performed on painted building components at the address &amp; apartment mentioned above.</p>
	<p>An X-Ray Fluorescence Device (XRF) was used to test the painted components. Following you will find the results of the Lead-Based Paint inspection that was performed on these components.</p>
	<p>Included with this report is a Lead Paint Certification sheet. This certification sheet contains pertinent information including but not limited to license numbers, property location, date of inspection and instrumentation use for testing.</p>
	<p>The enclosed data charges contain the results of this inspection. The important figures on the charts are in the combined columns. The Action Level for lead in paint in New York, as set forth by the United States Environmental Protection Agency (EPA) , the Department of Housing and Urban Development (HUD), and by New York City Local Law 66 of 2019 is 0.5 milligram per square centimeter (mg/cm2)</p>

	<p style="text-align: center;"><span style="color: #5A99D4;">*Results below 0.5mg/cm2 are considered acceptable</span><br><span style="color: #ea3e23;">*Results equal to or above 0.5mg/cm2 are considered positive</span> for lead-based paint.</p>

	<p style="margin-top:30px;">The calibration results of the XRF device, which was used for the painted surface testing, are listed on the Data charts. The XRF calibration tests are the first 6 readings and the last 6 readings on the chart. These tests are taken in order to ensure that the XRF device is operating properly. Lead Paint Standards supplied by the XRF device manufacturer (Viken) are used for the calibration tests. All the calibration tests were successful, as noted on the Data Charts.</p> 
</div>

	<div style="padding: 40px 60px; page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<p>Attached is a copy of the lead paint inspection report. The first pages of the report indicate the areas tested. The readings are detailed by room, wall and position on the wall. In each room side A is always the same wall as the room entry, with sides B, C and D following in a clockwise direction. The sides are noted in the data charts.</p>
	
		<p style="margin: 35px 0px;"><strong>A total of <span style="color: #5A99D4;"><#-OF-READINGS></span> reading, including <span style="color: #5A99D4;">12</span> calibration checks were taken on painted or varnished components. The results indicate that all tested areas registered readings below the regulatory limit established by the NYC Lead Poisoning Prevention Program.</strong></p>

		<p><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>

		<p>*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>

		<p>Please call us with any questions or concerns that you may have regarding this report or any other lead-based paint issues.</p>

		<p style="height: 30px;"></p>
		<p>Sincerely,<br>Ramo Kucevic</p>
	</div>

	<div style="padding: 40px 60px; page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px; text-decoration: underline;margin-top: 30px;">LEAD – BASED PAINT INSPECTION REPORT</h1>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION FOR:</strong> <br> <span style="color: #5A99D4;"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">PERFORMED AT:</strong> <br> <span style="color: #5A99D4;"><ADDRESS><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION DATE:</strong> <br> <span><SCHEDULE-DATE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">JOB ID:</strong> <br> <span style="color: #5A99D4;"><JOB-ID></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">Start Time &amp; End Time</strong> <br> <span style="color: #5A99D4;">Start: <START-TIME><br>End: <END-TIME></span></p>
		<p style="font-size: 16px; line-height: 21px;"><strong style="text-decoration: underline;">INSTRUMENT TYPE:</strong> <br> <span>VIKEN detection Pb200i XRF Lead paint Analyzer Serial # <INSPECTOR-XRF-DEVICE><br>ACTION LEVEL: 0.5/cm2</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-top: 35px;"><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>
		<p style="font-size: 16px; line-height: 21px;">*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>
		<div style="display:flex;">
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Signed: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Date: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
		</div>
		<p style="font-size: 16px; line-height: 21px;"><INSPECTOR-NAME><br>EPA Certification # <INSPECTOR-CERTIFICATION-ID></p>
		<p style="font-size: 16px; line-height: 21px;">Raza Environmental Inc:<br>EPA Certification # LBP- F228826-2</p>

		<p style="font-size: 16px; line-height: 21px; margin-top:30px;"><strong>Viken Detection pb200i XRF Lead Paint Analyzer Serial # <INSPECTOR-XRF-DEVICE></strong></p>
	</div>



</body>
</html>`;
}

export const xrfEntireUnitPositiveTemplate = () =>{
	return `<!DOCTYPE html>
<html>
<head>
<title>XRF</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 20px; line-height: 26px; text-align: justify;">
 
<div style="padding: 40px 60px; position: relative; font-family: arial;">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="color: #5A99D4"><TODAY-DATE></p>

	<p style="color: #5A99D4"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></p>

	<p style="margin-top: 25px;">Re: Premises:<br> <span style="color: #5A99D4"><ADDRESS><br><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>

	<h1 style="text-align: center; font-size: 26px; margin-bottom: 20px; text-decoration: underline;margin-top: 30px; color: #ea3e23;">Lead - Based Paint Present</h1>

	<p style="margin-top: 0;">On <span style="color: #5A99D4"><SCHEDULE-DATE></span> lead-based paint testing was performed on painted building components at the address &amp; apartment mentioned above.</p>
	<p>An X-Ray Fluorescence Device (XRF) was used to test the painted components. Following you will find the results of the Lead-Based Paint inspection that was performed on these components.</p>
	<p>Included with this report is a Lead Paint Certification sheet. This certification sheet contains pertinent information including but not limited to license numbers, property location, date of inspection and instrumentation use for testing.</p>
	<p>The enclosed data charges contain the results of this inspection. The important figures on the charts are in the combined columns. The Action Level for lead in paint in New York, as set forth by the United States Environmental Protection Agency (EPA), the Department of Housing and Urban Development (HUD), and by New York City Local Law 66 of 2019 is 0.5 milligram per square centimeter (mg/cm2)</p>

	<p style="text-align: center;"><span style="color: #5A99D4;">*Results below 0.5mg/cm2 are considered acceptable <br>Results equal to or above 0.5mg/cm2 are considered</span> <span style="color: #ea3e23;">positive</span> for lead-based paint.</p>

	<p style="margin-top:30px;">The calibration results of the XRF device, which was used for the painted surface testing, are listed on the Data charts. The XRF calibration tests are the first 6 readings and the last 6 readings on the chart. These tests are taken in order to ensure that the XRF device is operating properly. Lead Paint Standards supplied by the XRF device manufacturer (Viken) are used for the calibration tests. All the calibration tests were successful, as noted on the Data Charts.</p> 
	</div>

	<div style="padding: 40px 60px;   page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<p>Attached is a copy of the lead paint inspection report. The first pages of the report indicate the areas tested. The readings are detailed by room, wall and position on the wall. In each room side A is always the same wall as the room entry, with sides B, C and D following in a clockwise direction. The sides are noted in the data charts.</p>
	
		<p style="margin: 35px 0px;"><strong>A total of <#-OF-READINGS> reading, including 12 calibration checks were taken on painted or varnished components. The results indicate that <span style="color: #ea3e23;"><#-OF-POSITIVES> positive / inconclusive</span> reading(s) were found during this inspection.</strong></p>

		<p><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>

		<p>*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>

		<p>Please call us with any questions or concerns that you may have regarding this report or any other lead-based paint issues.</p>

		<p style="height: 30px;"></p>
		<p>Sincerely,<br>Ramo Kucevic</p>
	</div>

	<div style="padding: 40px 60px; page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<h1 style="text-align: center; font-size: 22px; margin-bottom: 30px; text-decoration: underline;margin-top: 30px;">LEAD – BASED PAINT INSPECTION REPORT</h1>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION FOR:</strong> <br> <span style="color: #5A99D4;"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION PERFORMED AT:</strong> <br> <span style="color: #5A99D4;"><ADDRESS><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION DATE:</strong> <br> <span><SCHEDULE-DATE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">JOB ID:</strong> <br> <span style="color: #5A99D4;"><JOB-ID></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">Start Time &amp; End Time</strong> <br> <span style="color: #5A99D4;">Start: <START-TIME><br>End: <END-TIME></span></p>
		<p style="font-size: 16px; line-height: 21px;"><strong style="text-decoration: underline;">INSTRUMENT TYPE:</strong> <br> <span>VIKEN detection Pb200i XRF Lead paint Analyzer Serial # <INSPECTOR-XRF-DEVICE><br>ACTION LEVEL: 0.5/cm2</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-top: 35px;"><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>
		<p style="font-size: 16px; line-height: 21px;">*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>
		<div style="display:flex;">
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Signed: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Date: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
		</div>
		<p style="font-size: 16px; line-height: 21px;"><INSPECTOR-NAME><br>EPA Certification # <INSPECTOR-CERTIFICATION-ID></p>
		<p style="font-size: 16px; line-height: 21px;">Raza Environmental Inc:<br>EPA Certification # LBP- F228826-2</p>

		<p style="font-size: 16px; line-height: 21px; margin-top:30px;"><strong>Viken Detection pb200i XRF Lead Paint Analyzer Serial # <INSPECTOR-XRF-DEVICE></strong></p>
	</div>



</body>
</html>`;
}

export const xrfFrictionPositiveTemplate = () => {
	return `<!DOCTYPE html>
<html >
<head>
<title>XRF</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0;  font-size: 20px; line-height: 26px; text-align: justify;">
 
<div style="padding: 40px 60px; position: relative; font-family: arial;">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="color: #5A99D4"><TODAY-DATE></p>

	<p style="color: #5A99D4"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></p>

	<p style="margin-top: 25px;">Re: Premises:<br> <span style="color: #5A99D4"><ADDRESS><br><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>

	<h1 style="text-align: center; font-size: 20px; margin-bottom: 20px; text-decoration: underline;margin-top: 30px; color: #ea3e23;line-height: 1.3;">Friction surfaces tested ONLY <br>Lead-Based Paint Present
	</h1>

	<p style="margin-top: 0;">On <span style="color: #5A99D4"><SCHEDULE-DATE></span> lead-based paint testing was performed on painted friction components at the address &amp; apartment mentioned above.</p>
	<p>An X-Ray Fluorescence Device (XRF) was used to test the painted friction components. Following you will find the results of the Lead-Based Paint inspection that was performed on these components.</p>
	<p>Included with this report is a Lead Paint Certification sheet. This certification sheet contains pertinent information including but not limited to license numbers, property location, date of inspection and instrumentation use for testing.</p>
	<p>The enclosed data charges contain the results of this inspection. The important figures on the charts are in the combined columns. The Action Level for lead in paint in New York, as set forth by the United States Environmental Protection Agency (EPA) and the Department of Housing and Urban Development (HUD), is 0.5 milligram per square centimeter (mg/cm2)</p>

	<p style="text-align: center;"><span style="color: #5A99D4;">*Results below 0.5mg/cm2 are considered acceptable</span><br> <span style="color: #ea3e23;">Results equal to or above 0.5mg/cm2 are considered positive</span> for lead-based paint.</p>

	<p style="margin-top:30px;">The calibration results of the XRF device, which was used for the painted surface testing, are listed on the Data charts. The XRF calibration tests are the first 6 readings and the last 6 readings on the chart. These tests are taken in order to ensure that the XRF device is operating properly. Lead Paint Standards supplied by the XRF device manufacturer (Viken) are used for the calibration tests. All the calibration tests were successful, as noted on the Data Charts.</p> 
</div>
<div style="padding: 40px 60px; page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<p>Attached is a copy of the lead paint inspection report. The first pages of the report indicate the areas tested. The readings are detailed by room, wall and position on the wall. In each room side A is always the same wall as the room entry, with sides B, C and D following in a clockwise direction. The sides are noted in the data charts.</p>
	
		<p style="margin: 35px 0px; font-family: Times New Roman;"><strong>A total of <#-OF-READINGS> reading, including 12 calibration checks were taken on painted or varnished components. The results indicate that <span style="color: #ea3e23;"><#-OF-POSITIVES> positive / inconclusive</span> reading(s) were found during this inspection.</strong></p>

		<p><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>

		<p>*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>

		<p>Please call us with any questions or concerns that you may have regarding this report or any other lead-based paint issues.</p>

		<p style="height: 30px;"></p>
		<p>Sincerely,<br>Ramo Kucevic</p>
	</div>

<div style="padding: 40px 60px; page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px; text-decoration: underline;margin-top: 30px;">LEAD – BASED PAINT INSPECTION REPORT</h1>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION FOR:</strong> <br> <span style="color: #5A99D4;"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION PERFORMED AT:</strong> <br> <span style="color: #5A99D4;"><ADDRESS><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION DATE:</strong> <br> <span><SCHEDULE-DATE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">JOB ID:</strong> <br> <span style="color: #5A99D4;"><JOB-ID></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">Start Time &amp; End Time</strong> <br> <span style="color: #5A99D4;">Start: <START-TIME><br>End: <END-TIME></span></p>
		<p style="font-size: 16px; line-height: 21px;"><strong style="text-decoration: underline;">INSTRUMENT TYPE:</strong> <br> <span>VIKEN detection Pb200i XRF Lead paint Analyzer Serial # <INSPECTOR-XRF-DEVICE> ACTION LEVEL: 0.5/cm2</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-top: 35px;"><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>
		<p style="font-size: 16px; line-height: 21px;">*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>
		<div style="display:flex;">
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Signed: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Date: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
		</div>
		<p style="font-size: 16px; line-height: 21px;"><INSPECTOR-NAME><br>EPA Certification # <INSPECTOR-CERTIFICATION-ID></p>
		<p style="font-size: 16px; line-height: 21px;">Raza Environmental Inc:<br>EPA Certification # LBP- F228826-2</p>

		<p style="font-size: 16px; line-height: 21px; margin-top:30px;"><strong>Viken Detection pb200i XRF Lead Paint Analyzer Serial # <INSPECTOR-XRF-DEVICE></strong></p>
	</div>



</body>
</html>`;
}

export const xrfFrictionNegativeTemplate = () => {
	return `<!DOCTYPE html>
<html>
<head>
<title>XRF Friction</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0;  font-size: 20px; line-height: 26px; text-align: justify;">
 
<div style="padding: 40px 60px; position: relative; font-family: arial;">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="color: #5A99D4"><TODAY-DATE></p>

	<p style="color: #5A99D4"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></p>

	<p style="margin-top: 25px;">Re: Premises:<br> <span style="color: #5A99D4"><ADDRESS><br><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>

	<h1 style="text-align: center; font-size: 20px; margin-bottom: 20px; text-decoration: underline;margin-top: 30px;line-height: 1.3;">
	Friction surfaces tested ONLY <br>Lead - Based paint within NYC acceptable Thresholds </h1>

	<p style="margin-top: 0;">On <span style="color: #5A99D4"><SCHEDULE-DATE></span> lead-based paint testing was performed on painted friction components at the address &amp; apartment mentioned above.</p>
	<p>An X-Ray Fluorescence Device (XRF) was used to test the painted friction components. Following you will find the results of the Lead-Based Paint inspection that was performed on these components.</p>
	<p>Included with this report is a Lead Paint Certification sheet. This certification sheet contains pertinent information including but not limited to license numbers, property location, date of inspection and instrumentation use for testing.</p>
	<p>The enclosed data charges contain the results of this inspection. The important figures on the charts are in the combined columns. The Action Level for lead in paint in New York, as set forth by the United States Environmental Protection Agency (EPA) and the Department of Housing and Urban Development (HUD), is 0.5 milligram per square centimeter (mg/cm2)</p>

	<p style="text-align: center;"><span style="color: #5A99D4;">*Results below 0.5mg/cm2 are considered acceptable</span><br><span style="color: #ea3e23;">*Results equal to or above 0.5mg/cm2 are considered positive</span> for lead-based paint.</p>

	<p style="margin-top:30px;">The calibration results of the XRF device, which was used for the painted surface testing, are listed on the Data charts. The XRF calibration tests are the first 6 readings and the last 6 readings on the chart. These tests are taken in order to ensure that the XRF device is operating properly. Lead Paint Standards supplied by the XRF device manufacturer (Viken) are used for the calibration tests. All the calibration tests were successful, as noted on the Data Charts.</p> 
</div>
<div style="padding: 40px 60px; page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<p>Attached is a copy of the lead paint inspection report. The first pages of the report indicate the areas tested. The readings are detailed by room, wall and position on the wall. In each room side A is always the same wall as the room entry, with sides B, C and D following in a clockwise direction. The sides are noted in the data charts.</p>
	 
		<p><strong style="color: #5A99D4; text-decoration: underline;">Disclosure:</strong> XRF devices Should not be considered 100% accurate, therefore Raza Environmental Inc assumes no liability
for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional
sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as
contractors/inspectors is limited to the total fee paid by the client.</p>

		<p>*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and
date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the
testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may
contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-
based paint report should be kept by the owner and all future owners for the life of the dwelling.</p>

<p>A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law
(24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are
also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their
children from lead-based paint hazards.</p>

<p style="margin: 35px 0px;"><strong>A total of <span style="color: #5A99D4;"><#-OF-READINGS></span> reading, including <span style="color: #5A99D4;">12</span> calibration checks were taken on painted or varnished components. The
results indicate that all tested areas registered readings below the regulatory limit established by the NYC
Lead Poisoning Prevention Program.</strong></p>

		<p>Please call us with any questions or concerns that you may have regarding this report or any other lead-based paint issues.</p>

		<p style="height: 30px;"></p>
		<p>Sincerely,<br>Ramo Kucevic</p>
	</div>

<div style="padding: 40px 60px; page-break-before:always">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px; text-decoration: underline;margin-top: 30px;">LEAD – BASED PAINT INSPECTION REPORT</h1>
		<p style="font-size: 16px; line-height: 18px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION FOR:</strong> <br> <span style="color: #5A99D4;"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">PERFORMED AT:</strong> <br> <span style="color: #5A99D4;"><ADDRESS><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION DATE:</strong> <br> <span><SCHEDULE-DATE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">JOB ID:</strong> <br> <span style="color: #5A99D4;"><JOB-ID></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">Start Time &amp; End Time</strong> <br> <span style="color: #5A99D4;">Start: <START-TIME><br>End: <END-TIME></span></p>
		<p style="font-size: 16px; line-height: 21px;"><strong style="text-decoration: underline;">INSTRUMENT TYPE:</strong> <br> <span>VIKEN detection Pb200i XRF Lead paint Analyzer Serial # <INSPECTOR-XRF-DEVICE> ACTION LEVEL: 0.5/cm2</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-top: 35px;"><strong style="color: #5A99D4; text-decoration: underline;">Disclosure:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>
		<p style="font-size: 16px; line-height: 21px;">*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>
		<div style="display:flex;">
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Signed: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Date: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
		</div>
		<p style="font-size: 16px; line-height: 21px;"><INSPECTOR-NAME><br>EPA Certification # <INSPECTOR-CERTIFICATION-ID></p>
		<p style="font-size: 16px; line-height: 21px;">Raza Environmental Inc:<br>EPA Certification # LBP- F228826-2</p>

		<p style="font-size: 16px; line-height: 21px; margin-top:30px;"><strong>Viken Detection pb200i XRF Lead Paint Analyzer Serial # <INSPECTOR-XRF-DEVICE></strong></p>
	</div>



</body>
</html>`;
}

export const paintChipTemplate = () => {
  return `<!DOCTYPE html>
<html>
<head>
<title>Paint Chips</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 18px; line-height: 24px; text-align: justify;">
 
<div style="padding: 40px 60px; position: relative; font-family: arial;">
	<div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="font-size: 18px;"><TODAY-DATE></p>
	<p style="font-size: 18px; margin-bottom: 30px;">The City of New York <br>
		Department of Housing, Preservation and Development<br>
		Division of Code Enforcement<br>
		Lead Based Paint Inspection Unit<br>
		94 Old Broadway, 7th Floor<br>
		New York, NY 10027
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Address:</strong> 
		<span style="width: 100%; display: inline-block;"><ADDRESS>, Apt. <APT><br><CITY> , <STATE> <ZIP-CODE><br>Year Built: <YEAR-BUILT></span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Address of Owner:</strong>
		<span style="width: 100%; display: inline-block;"><BUILDING-CORPORATION-NAME> <br>
			<MAILING-ADDRESS><br>
			<MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Testing Contractor:</strong> 
		<span style="width: 100%; display: inline-block;">RAZA Environmental Inc.<br>
			35 East Grassy Sprain Road,<br>
			Suite 302, Yonkers, NY 10710</span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Description of test results:</strong> 
		<span style="width: 100%; display: inline-block;"><em>Attached</em></span>
	</p>

	<p style="display: flex;">
		<strong style="width: 260px; display: inline-block; margin-right: 14px;">Laboratory used:</strong> 
		<span style="width: 100%; display: inline-block;">Eastern Analytical Services Inc. <br>
4 Westchester Plaza <br>
Elmsford, NY 10523 - 1610</span>
	</p>

	<p style="margin-top: 30px;"><strong style="text-decoration: underline;"><NO-OF-SAMPLES> paint chip samples</strong> were taken on <strong style="text-decoration: underline;"><SCHEDULE-DATE></strong> by EPA Certified lead inspector <strong style="text-decoration: underline;"><INSPECTOR-NAME></strong> (EPA certificate # <INSPECTOR-CERTIFICATION-ID> attached). They were analyzed by Eastern Analytical Services Inc – NYS Lab #10851, for lead paint chip in accordance with EPA method 3050B/700B. <strong style="text-decoration: underline;">All areas achieved the federal guidelines for lead in the paint chip unless otherwise specified.</strong></p>  

	<p>If you have any questions or require additional information, please feel free to contact me at the number above.</p>

	<p style="margin:0px;">Sincerely,</p> 
	<p style="height: 30px;"></p>
	<p>Ramo Kucevic <br>Director of Lead Paint Operations<br>Attachment - EAS Report</p> 

	 

</div>

</body>
</html>`;
};

export const commonHallsPositive = () => {
  return `<!DOCTYPE html>
<html>
<head>
<title>Common Hall</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 20px; line-height: 26px; text-align: justify;">
 
<div style=" position: relative; font-family: arial;">
	<div style="padding: 40px 60px;">
    <div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="color: #5A99D4"><TODAY-DATE></p>

	<p style="color: #5A99D4"<BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></p>

	<p style="margin-top: 25px;">Re: Premises:<br> <span style="color: #5A99D4"><ADDRESS><br><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>

	<h1 style="text-align: center; font-size: 20px; margin-bottom: 20px; text-decoration: underline;margin-top: 30px; color: #ea3e23;">Lead - Based Paint Present</h1>

	<p style="margin-top: 0;">On <span style="color: #5A99D4"><SCHEDULE-DATE></span> lead-based paint testing was performed on painted building components in the Common areas at the address mentioned above.</p>
	<p>An X-Ray Fluorescence Device (XRF) was used to test the painted components. Following you will find the results of the Lead-Based Paint inspection that was performed on these components.</p>
	<p>Included with this report is a Lead Paint Certification sheet. This certification sheet contains pertinent information including but not limited to license numbers, property location, date of inspection and instrumentation use for testing.</p>
	<p>The enclosed data charges contain the results of this inspection. The important figures on the charts are in the combined columns. The Action Level for lead in paint in New York, as set forth by the United States Environmental Protection Agency (EPA), the Department of Housing and Urban Development (HUD), and by New York City Local Law 66 of 2019 is 0.5 milligram per square centimeter (mg/cm2)</p>

	<p style="text-align: center;"><span style="color: #5A99D4;">*Results below 0.5mg/cm2 are considered acceptable <br>Results equal to or above 0.5mg/cm2 are considered</span> <span style="color: #ea3e23;">positive</span> for lead-based paint.</p>

	<p style="margin-top:30px;">The calibration results of the XRF device, which was used for the painted surface testing, are listed on the Data charts. The XRF calibration tests are the first 6 readings and the last 6 readings on the chart. These tests are taken in order to ensure that the XRF device is operating properly. Lead Paint Standards supplied by the XRF device manufacturer (Viken) are used for the calibration tests. All the calibration tests were successful, as noted on the Data Charts.</p> </div>
</div>
	<div style="padding: 40px 60px; page-break-before:always">
    <div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<p>Attached is a copy of the lead paint inspection report. The first pages of the report indicate the areas tested. The readings are detailed by room, wall and position on the wall. In each room side A is always the same wall as the room entry, with sides B, C and D following in a clockwise direction. The sides are noted in the data charts.</p>
	
		<p style="margin: 35px 0px; font-family: Times New Roman;"><strong>A total of <#-OF-READINGS> reading, including 12 calibration checks were taken on painted or varnished components. The results indicate that <span style="color: #ea3e23;"><#-OF-POSITIVES> positive / inconclusive</span> reading(s) were found during this inspection.</strong></p>

		<p><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>

		<p>*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>

		<p>Please call us with any questions or concerns that you may have regarding this report or any other lead-based paint issues.</p>

		<p style="height: 30px;"></p>
		<p>Sincerely,<br>Ramo Kucevic</p>
	</div>

	<div style="padding: 40px 60px; page-break-before:always">
    <div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px; text-decoration: underline;margin-top: 30px;">LEAD – BASED PAINT INSPECTION REPORT</h1>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION FOR:</strong> <br> <span style="color: #5A99D4;"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE>5</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION PERFORMED AT:</strong> <br> <span style="color: #5A99D4;"><ADDRESS><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION DATE:</strong> <br> <span><SCHEDULE-DATE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">JOB ID:</strong> <br> <span style="color: #5A99D4;"><JOB-ID></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">Start Time &amp; End Time</strong> <br> <span style="color: #5A99D4;">Start: <START-TIME><br>End: <END-TIME></span></p>
		<p style="font-size: 16px; line-height: 21px;"><strong style="text-decoration: underline;">INSTRUMENT TYPE:</strong> <br> <span>VIKEN detection Pb200i XRF Lead paint Analyzer Serial # <INSPECTOR-XRF-DEVICE><br>ACTION LEVEL: 0.5/cm2</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-top: 35px;"><strong style="color: #5A99D4; text-decoration: underline;">STATEMENT:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>
		<p style="font-size: 16px; line-height: 21px;">*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>
		<div style="display:flex;">
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Signed: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Date: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
		</div>
		<p style="font-size: 16px; line-height: 21px;"><INSPECTOR-NAME><br>EPA Certification # <INSPECTOR-CERTIFICATION-ID></p>
		<p style="font-size: 16px; line-height: 21px;">Raza Environmental Inc:<br>EPA Certification # LBP- F228826-2</p>

		<p style="font-size: 16px; line-height: 21px; margin-top:30px;"><strong>Viken Detection pb200i XRF Lead Paint Analyzer Serial # <INSPECTOR-XRF-DEVICE></strong></p>
	</div>



</body>
</html>`;
};

export const commonHallsNegative = () => {
  return `<!DOCTYPE html>
<html>
<head>
<title>Common Hall</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 20px; line-height: 26px; text-align: justify;">
 
<div style=" position: relative; font-family: arial;">
	<div style="padding: 40px 60px;">
    <div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>

	<p style="color: #5A99D4"><TODAY-DATE></p>

	<p style="color: #5A99D4"<BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE></p>

	<p style="margin-top: 25px;">Re: Premises:<br> <span style="color: #5A99D4"><ADDRESS><br><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>

	

	<h1 style="text-align: center; font-size: 20px; margin-bottom: 20px; text-decoration: underline;margin-top: 30px;line-height: 1.3;">Lead - Based Paint WITHIN NYC Acceptable Thresholds</h1>

	<p style="margin-top: 0;">On <span style="color: #5A99D4"><SCHEDULE-DATE></span> lead-based paint testing was performed on painted building components in the Common areas at the address mentioned above.</p>
	<p>An X-Ray Fluorescence Device (XRF) was used to test the painted components. Following you will find the results of the Lead-Based Paint inspection that was performed on these components.</p>
	<p>Included with this report is a Lead Paint Certification sheet. This certification sheet contains pertinent information including but not limited to license numbers, property location, date of inspection and instrumentation use for testing.</p>
	<p>The enclosed data charges contain the results of this inspection. The important figures on the charts are in the combined columns. The Action Level for lead in paint in New York, as set forth by the United States Environmental Protection Agency (EPA), the Department of Housing and Urban Development (HUD), and by New York City Local Law 66 of 2019 is 0.5 milligram per square centimeter (mg/cm2)</p>

	<p style="text-align: center;"><span style="color: #5A99D4;">*Results below 0.5mg/cm2 are considered acceptable </span><br><span style="color: #ea3e23;">Results equal to or above 0.5mg/cm2 are considered positive</span> for lead-based paint.</p>

	<p style="margin-top:30px;">The calibration results of the XRF device, which was used for the painted surface testing, are listed on the Data charts. The XRF calibration tests are the first 6 readings and the last 6 readings on the chart. These tests are taken in order to ensure that the XRF device is operating properly. Lead Paint Standards supplied by the XRF device manufacturer (Viken) are used for the calibration tests. All the calibration tests were successful, as noted on the Data Charts.</p> </div>
</div>
	<div style="padding: 40px 60px; page-break-before:always">
    <div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<p>Attached is a copy of the lead paint inspection report. The first pages of the report indicate the areas tested. The readings are detailed by room, wall and position on the wall. In each room side A is always the same wall as the room entry, with sides B, C and D following in a clockwise direction. The sides are noted in the data charts.</p>
	
		<p style="margin: 35px 0px;"><strong>A total of <span style="color: #5A99D4;"><#-OF-READINGS></span>  reading, including <span style="color: #5A99D4;">12</span> calibration checks were taken on painted or varnished components. The
results indicate that all tested areas registered readings below the regulatory limit established by the NYC
Lead Poisoning Prevention Program.</strong></p>

		<p><strong style="color: #5A99D4; text-decoration: underline;">Disclosure:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>

		<p>*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>

		<p>Please call us with any questions or concerns that you may have regarding this report or any other lead-based paint issues.</p>

		<p style="height: 30px;"></p>
		<p>Sincerely,<br>Ramo Kucevic</p>
	</div>

	<div style="padding: 40px 60px; page-break-before:always">
    <div style="display: flex; align-items: center; justify-content:space-between; margin-bottom: 40px;">
		<div>
			<img src="${process.env.REACT_APP_FRONTEND_URL}/images/raza-logo.png" style="width: 180px;">
		</div>
		<div>
			<p style="margin:0; text-align: right;">RAZA Environmental Inc.</p>
			<p style="margin:0; text-align: right;">35 East Grassy Sprain Road,</p>
			<p style="margin:0; text-align: right;">Suite 302, Yonkers, NY 10710</p>
			<p style="margin:0; text-align: right;">347-734-1865</p>
			<p style="margin:0; text-align: right;">www.RazaEnvironmental.com</p>
		</div>
	</div>
		<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px; text-decoration: underline;margin-top: 30px;">LEAD – BASED PAINT INSPECTION REPORT</h1>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION FOR:</strong> <br> <span style="color: #5A99D4;"><BUILDING-CORPORATION-NAME><br><MAILING-ADDRESS><br><MAILING-CITY>, <MAILING-STATE> <MAILING-ZIP-CODE><br><MAILING-CONTACT-PHONE>5</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION PERFORMED AT:</strong> <br> <span style="color: #5A99D4;"><ADDRESS><CITY> , <STATE> <ZIP-CODE><br>Apartment <APT><br>Year Built: <YEAR-BUILT></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">INSPECTION DATE:</strong> <br> <span><SCHEDULE-DATE></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">JOB ID:</strong> <br> <span style="color: #5A99D4;"><JOB-ID></span></p>
		<p style="font-size: 16px; line-height: 21px; margin-bottom: 15px;"><strong style="text-decoration: underline;">Start Time &amp; End Time</strong> <br> <span style="color: #5A99D4;">Start: <START-TIME><br>End: <END-TIME></span></p>
		<p style="font-size: 16px; line-height: 21px;"><strong style="text-decoration: underline;">INSTRUMENT TYPE:</strong> <br> <span>VIKEN detection Pb200i XRF Lead paint Analyzer Serial # <INSPECTOR-XRF-DEVICE><br>ACTION LEVEL: 0.5/cm2</span></p>
		<p style="font-size: 16px; line-height: 21px; margin-top: 35px;"><strong style="color: #5A99D4; text-decoration: underline;">Disclosure:</strong> XRF devices are NOT 100% accurate, therefore Raza Environmental Inc assumes no liability for any loss, injury, claims, or damages resulting from the use or reliance on this report, it is possible that additional sampling may be required to identify other pollutants. The liability of Raza Environmental Inc, as well as contractors/inspectors is limited to the total fee paid by the client.</p>
		<p style="font-size: 16px; line-height: 21px;">*The results of lead paint testing are valid only for the specific areas that the XRF device directly touched at the time and date of the inspection. These results pertain solely to the condition of those surfaces and the circumstances during the testing. This attached lead paint inspection report is not transferable. Please note that some painted surfaces may contain levels of lead below 0.5mg/cm2, which could create lead dust hazards if the paint is sanded or scraped. The lead-based paint report should be kept by the owner and all future owners for the life of the dwelling. A copy of this summary must also be provided to new lessees (tenants) &amp; purchasers of this property under Federal law (24CFR part 35 &amp; 40CFR part 745) before they become obligated under a lease or sales contract. Landlords and sellers are also required to distribute a pamphlet approved by the US EPA to ensure that parents have information to protect their children from lead-based paint hazards.</p>
		<div style="display:flex;">
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Signed: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
			<p style="width:50%; display: flex;font-size: 16px; line-height: 21px;">Date: <span style="border-bottom: 1px solid #000; width: 100%; display: block; margin-left: 5px;"></span></p>
		</div>
		<p style="font-size: 16px; line-height: 21px;"><INSPECTOR-NAME><br>EPA Certification # <INSPECTOR-CERTIFICATION-ID></p>
		<p style="font-size: 16px; line-height: 21px;">Raza Environmental Inc:<br>EPA Certification # LBP- F228826-2</p>

		<p style="font-size: 16px; line-height: 21px; margin-top:30px;"><strong>Viken Detection pb200i XRF Lead Paint Analyzer Serial # <INSPECTOR-XRF-DEVICE></strong></p>
	</div>



</body>
</html>`;
};