import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";

export default function BasicTimePicker({ Controller,control,setValue,name,watch, defaultValue,...rest }) {
    const [val, setVal] = React.useState(
      defaultValue 
    );
    const time = watch(name)
     React.useEffect(() => {
       if (time) {
         setVal(dayjs(time, "HH:mm:ss"));
       }
     }, [time]);
    
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name="time"
        control={control}
        
        render={({ field }) => (
          <TimePicker
            {...field}
            className="w-100"
            ampm={false}
            onChange={(newValue) => {
              if (newValue) {
                const formattedTime = newValue.format("HH:mm:ss");
                setValue(name, formattedTime); // Set the formatted time in the form state
                setVal(newValue); // Update local state
              } else {
                setValue(name, null); // Handle clearing the time
                setVal(null);
              }
            }}
            renderInput={(params) => <input {...params} />}
            {...rest}
            value={val}
            views={["hours", "minutes", "seconds"]}
            sx={{
              "& .MuiInputAdornment-root": {
                display: 'none'
              },
            }}
          />
        )}
        
      />
    </LocalizationProvider>
  );
}
