import React, { useRef } from "react";
import useDownloader from "react-use-downloader";

import downloadIcon from "../../../styles/svg/download-icon.svg";
import {
  dustWipe,
  commonAreas,
  entireUnitXRF,
  frictionSurface,
  paintChip,
} from "../../../pdf/affadevits";
import {
  getAffadevitData,
} from "../../../sevices/jobApi";
import {
  servicesVariables,
} from "../../../utils/constants";
import moment from "moment";
import { createPdf } from "./createPdfByTemplate";

function DownloadAffadevit({ params }) {
  const {  download} = useDownloader();

  let affedevitType = () => {};

  if (
    [
      servicesVariables.xrfEnitrUnitNegative,
      servicesVariables.xrfEnitrUnitPositive,
    ].includes(params.row.service_id)
  ) {
    affedevitType = entireUnitXRF;
  } else if (
    [
      servicesVariables.xrfFrictionPositive,
      servicesVariables.xrfFrictionNegative,
    ].includes(params.row.service_id)
  ) {
    affedevitType = frictionSurface;
  } else if ([servicesVariables.dustWipe].includes(params.row.service_id)) {
    affedevitType = dustWipe;
  } else if ([servicesVariables.paintChip].includes(params.row.service_id)) {
    affedevitType = paintChip;
  } else {
    affedevitType = commonAreas;
  }

  const getaffedevitData = async (data) => {
    await getAffadevitData(data)
      .then((res) => {

        const htmlString = affedevitType(
          params.row,
          res.data.data.response_data
        );
        createPdf(
          htmlString,
          download,
          `raza-affidavit-techcorp-${moment(params.row.scheduled_date).format(
            "MM-DD-YYYY"
          )}-${params.row.apartment}-${params.row.job_id}.pdf`,
          {inspector_id: data.inspector,add_inspector_attachment: true}
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadPdf = async () => {
    const data = await getaffedevitData({
      building: params.row.building_id,
      inspector: params.row.inspector_id,
    });
  };

  return (
    <>
      {params.row.inspector_id && (
        <img
          src={downloadIcon}
          onClick={downloadPdf}
          className="cursor-pointer "
        />
      )}
    </>
  );
}

export default DownloadAffadevit;
