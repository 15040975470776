import React from "react";

import IsRequired from "./IsRequired";
import Report from "./Report";

function KnobCovers({ params, refetchUnits }) {
  const { compliance } = params.row;
  const isRequired =
    compliance?.stove_knob_covers == true ||
    compliance?.permanent_stove_safety_knobs == true;

  return compliance?.status == "pending" ? (
    ""
  ) : (
    <IsRequired
      required={isRequired}
      attachment={compliance?.knob_covers_report_attachment}
    >
      <Report
        id={compliance.compliance_id}
        attachment={compliance?.knob_covers_report_attachment}
        refetchUnits={refetchUnits}
        status={"Report-Needed"}
        type={"knob_cover_report"}
      />
    </IsRequired>
  );
}

export default KnobCovers;
