import React from "react";

import IsRequired from "./IsRequired";
import Report from "./Report";

function WindowGuards({ params, refetchUnits }) {
  const { compliance } = params.row;
  const isRequired =
    compliance?.window_guards_installed == false ||
    compliance?.window_guards_installed_anyway == true ||
    compliance?.window_guards_need_repair == true ||
    compliance?.window_guards_need_repair_existing == true;

  return compliance?.status == "pending" ? (
    ""
  ) : (
    <IsRequired
      required={isRequired}
      attachment={compliance?.window_guard_report_attachment}
    >
      <Report
        id={compliance.compliance_id}
        attachment={compliance?.window_guard_report_attachment}
        refetchUnits={refetchUnits}
        status={"Report-Needed"}
        type={"window_guard_report"}
      />
    </IsRequired>
  );
}

export default WindowGuards;
