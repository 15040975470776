import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";

import PrimaryButton from "../../../../components/Button/Button";
import LongFormDialog from "../../../../components/Dialog/LongFormDialog";
import TicketForm from "../../../Tickets/components/TicketForm";
import { useBuildings } from "../../../../reactQuery/building";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import Report from "./Report";
import IsRequired from "./IsRequired";

function InspectionScheduled({ params, isFromUnit = false, refetchUnits }) {
  const { responseData, unitStatus, totalRecords } = useBuildings();
  const [refetch, setRefetch] = useCustomSearchParams("refetch");
  const [formData, setFormData] = useState({
    scheduled_date: "",
    service_id: "",
    building_id: params.row?.building_id,
    apartment: params?.row?.unit_number,
    unit_id: params?.row?.compliance?.unit_id,
    unit_status: "",
    borough: "",
    superintendent_name: "",
    superintendent_number: "",
    additional_comment: "",
    is_flexible: false,
    is_prior: false,
    compliance_id: params.row?.compliance?.compliance_id,
  });
  const [anchorEl, setAnchorEl] = useState(false);
  const { compliance } = params.row;

  const handleClose = () => {
    setAnchorEl(false);
    setRefetch("refetch", refetch ? refetch + 1 : 1);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  useEffect(() => {
    if (totalRecords && !isFromUnit) {
      const currentBuilding = responseData?.find(
        (el) => el.building_id == params.row?.building_id
      );
      const apt = currentBuilding?.units;

      const currentApt = apt?.find((el) => el.unit_id == compliance?.unit_id);

      setFormData((data) => {
        return {
          ...data,
          unit_size: currentApt?.unit_size,
          // unit_id: currentApt?.unit_id,
          unit_status: currentApt?.unit_status,
          borough: currentBuilding?.borough || "",
          superintendent_name: currentBuilding?.superintendent_name || "",
          superintendent_number: currentBuilding?.superintendent_number || "",
        };
      });
    }
  }, [totalRecords]);
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Create Ticket"}
          btnTitle={"Confirm"}
        >
          <Container>
            <div className="mb-32">
              <p className="schedule-appointment-heading">
                Schedule Appointment
              </p>
              <p className="form-instruction">
                Please complete all fields to schedule an appointment for
                service.
              </p>
            </div>
            <TicketForm initialValues={formData} onClose={handleClose} />
          </Container>
        </LongFormDialog>
      )}
      <div className="w-100 d-flex justify-content-center">
        {compliance?.status == "pending" || compliance?.status == null ? (
          ""
        ) : compliance?.status == "inspection_scheduled" ? (
          // <img src={Green} />
          <p>Scheduled</p>
        ) : compliance?.status == "in_process" ? (
          <IsRequired
            required={compliance?.child_under_6_required == true}
            attachment={compliance?.compliance_report_attachment}
          >
            <Report
              id={compliance.compliance_id}
              attachment={compliance?.compliance_report_attachment}
              refetchUnits={refetchUnits}
              status={"Report-Needed"}
              type={"compliance_report"}
            />
          </IsRequired>
        ) : compliance?.status == "completed" ? (
          compliance?.child_under_6_required == true ? (
            compliance?.compliance_report_attachment ? (
              <IsRequired
                required={true}
                attachment={compliance?.compliance_report_attachment}
              >
                <Report
                  id={compliance.compliance_id}
                  attachment={compliance?.compliance_report_attachment}
                  refetchUnits={refetchUnits}
                  status={"Completed"}
                  type={"compliance_report"}
                />
              </IsRequired>
            ) : (
              ""
            )
          ) : compliance?.child_under_10_not_living == true ||
            compliance?.child_under_10 == true ||
            compliance?.child_under_6 == false ? (
            <IsRequired required={false} />
          ) : (
            <IsRequired required={false} />
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default InspectionScheduled;
