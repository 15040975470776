import React from 'react'

import Green from "../../../../styles/svg/compliance-green.svg";
import Red from "../../../../styles/svg/red-cross-icon.svg";

function IsRequired({ required, attachment, children }) {
  return (
    <div className="d-flex align-items-center">
      {attachment ? (
        <p>Report Uploaded</p>
      ) : (
        <>
          <img src={required ? Red : Green} />
          <p className={`ml-1 ${required ? "text-red" : "text-green"}`}>
            {required ? "Required" : "Not Required"}
          </p>
        </>
      )}
      {required && children}
    </div>
  );
}

export default IsRequired