import React, { useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";

import PrimaryButton from "../../../../../components/Button/Button";
import LongFormDialog from "../../../../../components/Dialog/LongFormDialog";
import CreateTicket from "../../../../Tickets/components/CreateTicket";
import CreateBuilding from "./CreateBuilding";
import { useClientDetail } from "../../../../../reactQuery/client";
import toast from "react-hot-toast";

function Heading() {
  const isMobile = useMediaQuery({ maxWidth: "900px" });
  const isHideText = useMediaQuery({ maxWidth: "478px" });
  const redirect = useNavigate();
  const params = useParams();
  const { responseData, isLoading, isError, error } = useClientDetail(
    params.clientId
  );

  const role = JSON.parse(localStorage.getItem("userData")).role;

  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    if (role == "client" && responseData.status != "Active") {
      toast.error(
        `You cannot schedule testing because your account is ${responseData.status}.`
      );
    } else {
      setAnchorEl(true);
    }
  };

  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Create Ticket"}
          btnTitle={"Confirm"}
        >
          <CreateTicket onClose={handleClose} isFromClient={params.clientId} />
        </LongFormDialog>
      )}
      {anchorEl2 && (
        <LongFormDialog
          open={anchorEl2}
          handleClose={() => setAnchorEl2(false)}
          title={"Add Building Setup"}
          btnTitle={"Confirm"}
        >
          <CreateBuilding
            onClose={() => setAnchorEl2(false)}
            id={params.clientId}
            clientName={responseData?.company_name}
          />
        </LongFormDialog>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={4} md={4}>
            <p className="page-heading-name">Buildings</p>
            <p className="building-info">
              Click on building address to view units tested
            </p>
          </Grid>
          <Grid className={`d-flex justify-content-end`} item xs={8} md={8}>
            <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
              <PrimaryButton
                variant="outlined"
                className={`capitalize `}
                onClick={() => redirect('compliance')}
              >
                Annual Compliance
              </PrimaryButton>
              {role != "client" && (
                <PrimaryButton
                  variant="contained"
                  className={`capitalize btn-bg-blue-primary ${
                    isHideText ? "icon-m-0" : ""
                  }`}
                  onClick={() => setAnchorEl2(true)}
                  startIcon={<AddIcon />}
                >
                  {isHideText ? "" : "Add Building"}
                </PrimaryButton>
              )}
              <PrimaryButton
                variant="contained"
                className={`capitalize btn-bg-orange ${
                  isHideText ? "icon-m-0" : ""
                }`}
                onClick={handleClick}
              >
                Schedule Testing
              </PrimaryButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Heading;
