import React, { useRef } from "react";
import useDownloader from "react-use-downloader";

import downloadIcon from "../../../styles/svg/letter-download.svg";
import {
  dustWipeTemplate,
  paintChipTemplate,
  commonHallsPositive,
  xrfEnitrUnitNegativeTemplate,
  xrfEntireUnitPositiveTemplate,
  xrfFrictionPositiveTemplate,
  xrfFrictionNegativeTemplate,
  commonHallsNegative,
} from "../../../pdf/coverLetter";
import { getAffadevitData } from "../../../sevices/jobApi";
import { servicesVariables } from "../../../utils/constants";
import moment from "moment";
import { createPdf } from "./createPdfByTemplate";
import { convertToAMPM } from "../../../utils/helpers";

function DownloadCoverLetter({ params }) {
  const { download } = useDownloader();
  const { service_id, report_status, inspector_id } = params.row;
  const { xrfEnitrUnitNegative, xrfFrictionPositive, dustWipe, paintChip } =
    servicesVariables;

  let affedevitType = () => {};

  if (xrfEnitrUnitNegative === service_id) {
    if (report_status === "Positive")
      affedevitType = xrfEntireUnitPositiveTemplate;
    else if (report_status === "Negative")
      affedevitType = xrfEnitrUnitNegativeTemplate;
  } else if (xrfFrictionPositive === service_id) {
    if (report_status === "Positive")
      affedevitType = xrfFrictionPositiveTemplate;
    else if (report_status === "Negative")
      affedevitType = xrfFrictionNegativeTemplate;
  } else if (dustWipe === service_id) {
    affedevitType = dustWipeTemplate;
  } else if (paintChip === service_id) {
    affedevitType = paintChipTemplate;
  } else {
    if (report_status === "Positive")
      affedevitType = commonHallsPositive;
    else if (report_status === "Negative")
      affedevitType = commonHallsNegative;
  }

  const getaffedevitData = async (data) => {
    await getAffadevitData(data)
      .then((res) => {
        const { building_detail, client, inspector } =
          res.data.data.response_data;
        const clientAddress = client?.street_address + ' '  + client?.address; 
        const replacements = {
          "<TODAY-DATE>": moment().format("MM/DD/YYYY"),
          "<ADDRESS>": params.row.building_address || "",
          "<APT>": params.row.apartment || "",
          "<CITY>": building_detail?.city || "",
          "<STATE>": building_detail?.state || "",
          "<ZIP-CODE>": building_detail?.zip_code || "",
          "<JOB-ID>": params.row.job_id,
          "<SCHEDULE-DATE>": params.row.scheduled_date
            ? moment(params.row.scheduled_date).format("MM/DD/YYYY")
            : "",
          "<INSPECTOR-NAME>": params.row.inspector_name || "",
          "<INSPECTOR-CERTIFICATION-ID>": inspector.certificate_id || "",
          "<INSPECTOR-XRF-DEVICE>": inspector.xrf_devices || "",
          "<YEAR-BUILT>": building_detail?.borough || "",
          "<BUILDING-CORPORATION-NAME>":
            building_detail?.building_corporation || "",
          "<MAILING-ADDRESS>": clientAddress || "",
          "<MAILING-CITY>": client?.city || "",
          "<MAILING-STATE>": client?.state || "",
          "<MAILING-ZIP-CODE>": client?.zip_code || "",
          "<MAILING-CONTACT-PHONE>": client?.phone_number || "",
          "<START-TIME>": params.row.start_time
            ? convertToAMPM(params.row.start_time)
            : "",
          "<END-TIME>": params.row.end_time
            ? convertToAMPM(params.row.end_time)
            : "",
          "<NO-OF-SAMPLES>": params.row.num_of_samples_taken || "",
          "<#-OF-READINGS>": params.row.num_of_readings || "",
          "<#-OF-POSITIVES>": params.row.num_of_positives || "",
        };

        let htmlString = affedevitType();
        Object.keys(replacements).forEach((key) => {
          htmlString = htmlString.replaceAll(key, replacements[key]);
        });

        createPdf(
          htmlString,
          download,
          `raza-cover-letter-${moment(params.row.scheduled_date).format(
            "MM-DD-YYYY"
          )}-${params.row.apartment}-${params.row.job_id}.pdf`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadPdf = async () => {
    const data = await getaffedevitData({
      building: params.row.building_id,
      inspector: params.row.inspector_id,
    });
  };

  return (
    <>
      {["Positive", "Negative"].includes(report_status) && inspector_id && (
        <img
          src={downloadIcon}
          onClick={downloadPdf}
          className="cursor-pointer ml-1"
        />
      )}
    </>
  );
}

export default DownloadCoverLetter;
