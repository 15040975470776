import axios from "axios";
import { objectToFormData } from "../utils/helpers";
import Api from "./axios";

export const getLeadCompliance = async (query) => {
  return await Api.get(`/v1/compliance${query}`);
};

export const addLeadCompliance = async (data) => {
  return await Api.post(`/v1/compliance`, data);
};

export const addSurveyCompliance = async ({ survey_id, data, token }) => {
  return await Api.put(
    `/v1/compliance/update_by_survey`,
    objectToFormData(data),
    {
      headers: token ? { authorization: token } : {},
    }
  );
};

export const scanSurveyCompliance = async ({ survey_id, data, token }) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/compliance/survey`,
    objectToFormData(data),
    {
      headers: token ? { authorization: `${token}` } : {},
    }
  );
};

export const getMailersCompliance = async (year) => {
  return await Api.get(`/v1/compliance/mailer_year/${year}`);
};

export const downloadMailers = async (data) => {
  return await Api.post(`/v1/compliance/mailer`, data);
};

export const getStatusPdf = async (id) => {
  return await Api.get(`/v1/compliance/mailer/status/${id}`);
};

export const downloadPendingMailer = async (data) => {
  return await Api.post(`/v1/compliance/downloadPendingMailer`,data);
};

export const downloadSurveyForm = async (data) => {
  return await Api.post(`/v1/compliance/downloadSurveyForm`, data);
};

export const downloadAuditReport = async (data) => {
  return await Api.post(`/v1/compliance/downloadAuditReport`, data);
};

export const checkStatusAuditReport = async (id) => {
  return await Api.get(`/v1/compliance/task-status/${id}`);
};

//

export const complianceReportUpload = async (body) => {
  return await Api.post(`/v1/compliance/reportUpload`,objectToFormData(body));
};

export const complianceReportDelete = async (id,type) => {
  return await Api.put(
    `/v1/compliance/reportDeleted?compliance_id=${id}&report_type=${type}`
  );
};

