import { deletePdfAffadevit, downloadPdfAffadevit } from "../../../sevices/jobApi";

export const createPdf = async (htmlString,download,fileName,props={}) => {
  downloadPdfAffadevit({ html_content: htmlString ,...props})
    .then((res) => {

      download(
        `${process.env.REACT_APP_BASE_URL}${res.data.file_url}`,
        fileName
      );
      deletePdfAffadevit(
        res.data.file_url.split("/")[res.data.file_url.split("/").length - 1]
      )
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};