import moment from "moment";
import FlexibleIcon from "../../../styles/svg/flexible-icon.svg";

import EditIcon from "./EditIcon";
import ReportUpload from "./ReportUpload";
import ReportDownload from "./ReportDownload";
import reportIcon from "../../../styles/svg/report-icon.svg";
import DownloadAffadevit from "./DownloadAffadevit";
import Chat from "../../InspectorRole/chat";
import RedirectToClient from "./RedirectToClient";
import DeleteTicket from "./DeleteTicket";
import ViewDocument from "../../Client/detail/components/RecentUploads/ViewDocument";
import DownloadCoverLetter from "./DownloadCoverLetter";

export const columns = [
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).format("MM/DD/YYYY")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "client_name",
    headerName: "Client",
    renderCell: (params) => {
      return <RedirectToClient params={params} />;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
  },
  {
    field: "apartment",
    headerName: "Apt#",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 80,
    flex: 0.5,
    // editable: true,
    sortable: false,
  },
  {
    field: "unit_size",
    headerName: "Unit Size",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "unit_status",
    headerName: "Unit Status",
    renderCell: (params) => {
      return (
        <p
          className={`${params.value ? "status-chip" : ""} ${
            params.value == "Vacant" ? "bg-orangr-status" : "bg-blue-status"
          } `}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
  {
    field: "service_type",
    headerName: "Service Type",
    renderCell: (params) => {
      return (
        <p
          className={`${params.value ? "status-chip" : ""}`}
          style={{ backgroundColor: params.row.service_icon || "#11b50c" }}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 198,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
  {
    field: "is_flexible",
    headerName: "Flexible",
    renderCell: (params) => {
      return (
        <p className="w-100 d-flex justify-content-center">
          {params.value && <img src={FlexibleIcon} />}
        </p>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Flexible</p>
      </div>
    ),
    minWidth: 80,
    flex: 0.7,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
  {
    field: "inspector_name",
    headerName: "Inspector",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "report_status",
    headerName: "Status",
    renderCell: (params) => {
      //status_colour
      return (
        <p
          className={`${params.value ? "status-chip" : ""}`}
          style={{ backgroundColor: params.row.status_colour || "#11b50c" }}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 120,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
  {
    field: "report_available",
    headerName: "Report",
    renderCell: (params) => {
      return (
        <p className="w-100 d-flex justify-content-center">
          {params.row.report_status == "No Access" ||
          params.row.report_status == "Cancelled" ? (
            ""
          ) : params.value ? (
            <ViewDocument params={params} icon={reportIcon} />
          ) : (
            <ReportUpload params={params} />
          )}
        </p>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Report</p>
      </div>
    ),
    minWidth: 100,
    flex: 0.6,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className="d-flex ">
          <div className="mr-1 d-flex align-items-center">
            <Chat params={params} />
          </div>
          <EditIcon params={params} />
          <DeleteTicket params={params} />
          <DownloadAffadevit params={params} />
          <DownloadCoverLetter params={params} />
        </div>
      );
    },
    minWidth: 165,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
];
